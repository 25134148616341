<template>
  <div class="home">
    <div class="leftTable">
      <leftTable></leftTable>
    </div>
    <div class="rightBox">
      <top-header></top-header>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import topHeader from "@/components/topHeader";
import leftTable from "@/components/leftTable";
export default {
  data() {
    return {};
  },
  components: {
    topHeader,
    leftTable,
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  .rightBox {
    padding-top: 50px;
    padding-left: 210px;
    width: 100%;
    box-sizing: border-box;
  }
}
</style>
