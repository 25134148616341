<template>
  <div class="leftNavigation">
    <el-menu
      class="el-menu-vertical-demo"
      background-color="rgb(48, 65, 86)"
      text-color="#fff"
      active-text-color="rgb(64, 158, 255)"
      :default-active="$route.path"
      :unique-opened="true"
      :router="true"
    >
      <el-menu-item index="#">
        <img
          class="logo"
          src="https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png"
          alt=""
        />
        <span slot="title">LiRi Manage System</span>
      </el-menu-item>
      <div v-for="(item, key) in leftTitleArr" :key="key">
        <el-submenu
          :index="item.url"
          v-if="item.children && item.children.length"
        >
          <template slot="title">
            <svg-icon :icon-class="item.icon" />
            <span>{{ item.name }}</span>
          </template>
          <div v-for="items in item.children" :key="items.name">
            <template>
              <el-menu-item-group v-if="!items.children.length">
                <el-menu-item :index="items.url">{{ items.name }}</el-menu-item>
              </el-menu-item-group>
              <el-submenu :index="items.url" v-else>
                <template slot="title">{{ items.name }}</template>
                <el-menu-item
                  :index="sonitem.url"
                  v-for="sonitem in items.children"
                  :key="sonitem.name"
                  >{{ sonitem.name }}</el-menu-item
                >
              </el-submenu>
            </template>
          </div>
        </el-submenu>
        <el-menu-item :index="item.url" v-else>
          <svg-icon :icon-class="item.icon" />
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
import store from "@/store";
export default {
  data() {
    return {
      leftTitleArr: [],
    };
  },
  mounted() {
    if ( store.getters.userObject)
    this.leftTitleArr = store.getters.userObject.userRoleVos[0].menuList;
    //  console.log(this.leftTitleArr);
  },
};
</script>

<style lang='scss' scoped>
.leftNavigation {
  position: fixed;
  left: 0;
  top: 0px;
   width: 210px;
  z-index: 101;
  background: rgb(48, 65, 86);
  height: 100%;

  .svg-icon {
    margin-right: 10px;
  }
  .el-menu {
    border: none;
  }
  .demo-block.hover {
    box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
      0 2px 4px 0 rgba(232, 237, 250, 0.5);
  }
  .logo {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
}
.leftNavigation::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.leftNavigation {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
