<template>
  <div class="breadcrumb">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">丽日管理后台</el-breadcrumb-item>
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
    };
  },
  watch: {
    $route() {
      // console.log(this.$router)
      // console.log(this.$route)
      this.title = this.$router.currentRoute.meta.title;
    },
  },
  created() {
    this.title = this.$router.currentRoute.meta.title;
  },
  mounted(){
    // console.log(this.$router)
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  padding: 16px 0;
}
</style>
